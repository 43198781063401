import Parseable from '@/misc/Parseable';

export default class NewsBackground extends Parseable {
    public id!: string;
    public image!: string;
    public textColor!: string;

    /**
     * parses an object to a news object
     * @param object
     */
    public static parseFromObject(object: any): NewsBackground {
        const news = new NewsBackground();
        Object.assign(news, object);
        return news;
    }

    public parseToObject(): Partial<NewsBackground> {
        return { ...this };
    }
}
