import {NewsIconCategoryInterface} from "@/interfaces/NewsIconCategoryInterface";

export const NewsIcons: NewsIconCategoryInterface[] = [
    {
        image: 'discount.png',
        title: 'DISCOUNT',
    },
    {
        image: 'bio.png',
        title: 'BIO',
    },
    {
        image: 'vegetarian.png',
        title: 'VEGETARIAN',
    },
    {
        image: 'vegan.png',
        title: 'VEGAN',
    },
    {
        image: 'regional.png',
        title: 'REGIONAL',
    },
    {
        image: 'new.png',
        title: 'NEW',
    },
    {
        image: 'gluten.png',
        title: 'GLUTEN_FREE',
    }
];