
import {namespace} from 'vuex-class';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {minLength, required} from 'vuelidate/lib/validators';
import News from "../../models/News.model";
import {FeedStoreActions, INFO_FEED_STORE_NAME} from "../../store/feed.store";
import {APPLICATION_STORE_NAME, ApplicationStoreGetters} from "@/store/application.store";
import Company from "@/models/Company";
import {NewsType} from "@/enum/NewsType.enum";
import {NewsIcon} from "@/enum/NewsIcon.enum";
import {DateTime} from "luxon";
import NewsRepository from "@/api/repositories/NewsRepository";
import NewsBackground from "@/models/NewsBackground";
import {NewsIconCategoryInterface} from "@/interfaces/NewsIconCategoryInterface";
import {NewsIcons} from "@/misc/NewsIcons";
import {greaterThan, maxLength} from "@/misc/CustomValidators";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";

const FeedStore = namespace(INFO_FEED_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    CustomStepperComponent: () => import(
      /* webpackChunkName: "CustomStepperComponent" */
      '@/components/Misc/CustomStepper.component.vue'
    ),
  },
  mixins: [validationMixin],
  validations: {
    newsCopy: {
      subTitle: { required, maxLength: maxLength(MaxLengthValidation.NEWS_SUB_TITLE) },
      price: { required, greaterThan: greaterThan(0)},
      description: { maxLength: maxLength(MaxLengthValidation.NEWS_DESCRIPTION)},
      type: { required },
      icons: { required, minLength: minLength(1)},
    },
  }
})
export default class CreateNewsComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop()
  public news?: News;

  @Prop({default: false})
  public isEditing!: boolean;

  @Prop({default: false})
  public isDuplicating!: boolean;

  @Prop({ default: true })
  public isModal!: boolean;

  @FeedStore.Action(FeedStoreActions.CREATE)
  private createFeedAction!: (news: News) => Promise<News>;

  @FeedStore.Action(FeedStoreActions.UPDATE)
  private updateFeedAction!: (news: News) => Promise<News>;

  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  private currentCompany?: Company;

  private newsIconItems: NewsIconCategoryInterface[] = NewsIcons;

  /**
   * flag that indicates if the error message inside the subTitle step should be shown
   * @private
   */
  private showMissingSubTitle = false;

  /**
   * flag that indicates if the error message inside the label selection step should be shown
   * @private
   */
  private showMissingSelectedLabel = false;

  /**
   * flag that indicates if the date picker is shown
   * @private
   */
  private showDatePicker = false;

  /**
   * index of the current selected background
   * @private
   */
  private backgroundPicked = 0;

  /**
   * rows that are used inside the subTitle
   * @private
   */
  private subTitleRowAmount = 1;

  /**
   * min size the image can take
   * @private
   */
  private imageMinSize = 200;

  /**
   * size of the text area used inside the background picker / subTitle section
   * @private
   */
  private imagePickerFontSize = 32;

  /**
   * returns the height that can be used for the image, uses 200 by default and adds 32 for each row that the subTitle
   * has
   * @private
   */
  private get imageHeight() {
    return this.imageMinSize + this.imagePickerFontSize * this.subTitleRowAmount;
  }

  /**
   * gets the background image based on the current selected background
   * @private
   */
  private getBackground() {
    return this.getImage(this.backgrounds[this.backgroundPicked].image);
  }

  /**
   * gets the image based on the base api url and the image path
   * @param image
   * @private
   */
  private getImage(image: string) {
    const api = process.env.VUE_APP_API_URL!;
    return `${api}${image}`;
  }

  /**
   * Is Mobile Vuetify Breakpoint Triggered
   */
  public get isMobile() {
    return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
  }

  /**
   * checks if the current day is enabled
   * @param val
   * @private
   */
  private allowDate(val: any): boolean {
    if(!val) return true;
    if(this.newsCopy.type === NewsType.DAILY) return true;
    return new Date(val).getDay() === 1;
  }

  /**
   * is called when the background is picked, updates flag and calls the passed toggle method
   * @param toggle
   * @param index
   * @private
   */
  private onBackgroundPicked(toggle: any, index: any) {
    this.backgroundPicked = index;
    toggle();
  }

  /**
   * validations for each step, is used to check for certain validation in each step
   * @private
   */
  private steps = [
    {validations: ['newsCopy.type'], valid: true},
    {validations: ['newsCopy.subTitle'], valid: true, callback: this.onInvalidSubTitleCallback},
    {validations: ['newsCopy.description', 'newsCopy.price'], valid: true},
    {validations: ['newsCopy.icons'], valid: true, callback: this.onInvalidSelectedLabelsCallback},
  ]

  /**
   * callback function that is called when the validation of the subTitle step is checked, sets the flag
   * @param valid
   * @private
   */
  private onInvalidSubTitleCallback(valid: boolean) {
    this.showMissingSubTitle = !valid;
  }

  /**
   * callback function that is called when the validation of the selected label step is checked or when labels have
   * changed
   * @param valid
   * @private
   */
  private onInvalidSelectedLabelsCallback(valid: boolean) {
    this.showMissingSelectedLabel = !valid;
  }

  /**
   * icons / labels that are used for the post
   * @private
   */
  private icons = [
    NewsIcon.DISCOUNT,
    NewsIcon.VEGAN,
    NewsIcon.VEGETARIAN,
    NewsIcon.BIO,
    NewsIcon.REGIONAL,
    NewsIcon.NEW,
    NewsIcon.GLUTEN_FREE
  ]

  /**
   * current step of the modal
   * @private
   */
  private currentStep = 0;

  /**
   * the maximum available step of the stepper
   * @private
   */
  private maxStepCount = 3;

  /**
   * copy of the news to have a "fresh" object to edit (to avoid editing the original object
   * in case that the user decides to revert his changes)
   * @private
   */
  private newsCopy: News = News.parseFromObject({});

  /**
   * different backgrounds for the post that the user can choose from
   * @private
   */
  private backgrounds: NewsBackground[] = [];

  /**
   * creates variable for dynamic text color based on the background that is used
   * @private
   */
  private get dynamicTextColor() {
    return {
      '--text-area-text-color': this.backgrounds[this.backgroundPicked].textColor,
    };
  }

  /**
   * inits the news object that is used to store and pass the entered inputs
   */
  private async created() {
    let copy = this.news ?? News.parseFromObject({type: NewsType.DAILY, icons: []});
    if(this.isDuplicating) {
      copy = News.parseFromObject({...copy, validFrom: '', validTo: null});
    }
    this.newsCopy = News.parseFromObject({...copy});

    const response = await NewsRepository.getBackgrounds();
    this.backgrounds = response.data.map((r: NewsBackground) => NewsBackground.parseFromObject(r));

    if(this.newsCopy.background) {
      const index = this.backgrounds.findIndex((r: NewsBackground) => r.id === this.newsCopy.background.id);
      if(index >= 0) this.backgroundPicked = index;
    }
  }

  /**
   * flag that indicates if the component is loading
   * @private
   */
  private isLoading = false;

  /**
   * gets the current dateTime for setting minimum inside the datePicker
   * @private
   */
  private get now() {
    if(this.newsCopy.type === NewsType.DAILY) {
      return DateTime.now().toString();
    } else {
      return DateTime.now().startOf('week').toString();
    }
  }

  /**
   * is called when the user selected a date, creates dateTime based on picked date and saves it to the v-model
   * @param date
   * @private
   */
  private onDateClicked(date: string) {
    const fDate = DateTime.fromISO(date);

    // Save Data to it's v-model
    (this.$refs.datePickerMenu as any).save(fDate);
  }

  /**
   * changes value of the label array, using splice here to update the table properly
   * @param index
   * @private
   */
  private changeLabel(index: number) {
    this.showMissingSelectedLabel = false;
    const icon = this.icons[index];

    const indexOfIcon = this.newsCopy.icons.indexOf(icon);
    if(indexOfIcon < 0) {
      this.newsCopy.icons.push(icon);
    } else {
      this.newsCopy.icons.splice(indexOfIcon, 1);
    }
  }

  /**
   * checks when the subTitle has changed, checks how many rows are used inside the text and sets the subTitleRowAmount
   * @private
   */
  @Watch('newsCopy.subTitle')
  private onSubTitleChanged() {
    if(!this.newsCopy.subTitle) return;
    this.subTitleRowAmount = this.newsCopy.subTitle.split(/\r\n|\r|\n/).length;
  }

  /**
   * returns if the icon with index is selected
   * @param index
   * @private
   */
  private isLabelSelected(index: number): boolean {
    return this.newsCopy.icons.includes(this.icons[index]);
  }

  /**
   * sets the news type to daily
   * @private
   */
  private setDailyType() {
    this.newsCopy.type = NewsType.DAILY;
  }

  /**
   * sets the news type to weekly
   * @private
   */
  private setWeeklyType() {
    this.newsCopy.type = NewsType.WEEKLY;

    // resets the date when changing to weekly type when the date wouldn't be allowed normally
    if(!this.allowDate(this.newsCopy.validFrom)) {
      this.newsCopy.validFrom = undefined;
    }
  }

  /**
   * is called when the save button is pressed, checks if a message was entered and edits the original news
   */
  private async saveData() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if(this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;

      // gets the iso formatted Date based on the date that the date picker provides
      let isoDate;
      if(this.newsCopy.validFrom) {
        isoDate = DateTime.fromISO(this.newsCopy.validFrom).toUTC();
      }

      const priceString = this.newsCopy.price.toString().replaceAll(',', '.');
      const price = parseFloat(priceString);

      // creates news payload for creating the news in the db
      const payload: News = News.parseFromObject({
        ...this.newsCopy,
        validFrom: isoDate,
        background: this.backgrounds[this.backgroundPicked].id,
        price: price * 100,
      });

      if(this.isEditing) {
        // tries to create the feed
        await this.updateFeedAction(payload);
      } else {
        // tries to create the feed
        await this.createFeedAction(News.parseFromObject({...payload, companyId: this.currentCompany!.id}));
      }

      this.isLoading = false;
      this.dismiss(true);
    } catch(e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CAN_ONLY_CREATE_ONE_NEWS');
            break;
          case 499:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.NEWS_ALREADY_STARTED');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the stepper is increased, increases the step and clamps it to maximum
   * @private
   */
  private async increaseStep() {
    // validates the current step to ensure the user made the correct inputs
    if(!await this.validateStep(this.steps[this.currentStep])) {
      this.steps[this.currentStep].callback?.(false);
      return;
    }

    this.steps[this.currentStep].callback?.(true);

    // checks if it is the final step and tries to create the news in the api
    if(this.currentStep === this.maxStepCount) {
      await this.saveData();
    }

    // goes to the next step
    if(this.currentStep < this.maxStepCount) this.currentStep++;
  }

  /**
   * validates a certain step by checking each validation parameter
   * @param step
   * @private
   */
  private async validateStep(step: any): Promise<boolean> {
    let isValid = true;
    for (let s of step.validations) {
      let valid = await this.checkForm(s);
      if (!valid) isValid = false;
    }
    step.valid = isValid;
    return isValid;
  }

  /**
   * checks a specific type
   * @param type
   * @private
   */
  private async checkForm(type: string): Promise<boolean> {
    const inputValid = this.triggerValidation(type);
    const affectedStep = this.steps.find(step => step.validations.indexOf(type) != -1);
    affectedStep!.valid = inputValid;
    return inputValid;
  }

  /**
   * formats the date that was picked
   * @private
   */
  private get getFormattedDate(): string {
    const date = this.newsCopy.validFrom;
    if(!date) return '';

    const dateTime = DateTime.fromISO(date?.toString());
    return this.$formatDate(dateTime);
  }

  /**
   * is called when the stepper is decreased, decreases the step and clamps it to minimum
   * @private
   */
  private decreaseStep() {
    if(this.currentStep > 0) this.currentStep--;
  }

  /**
   * closes the modal, sends flag in event that indicate if something has updated and the news should reload
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }
}
